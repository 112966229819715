<template>
    <TransitionRoot as="template" :show="isRegisterModalOpen">
        <Dialog as="div" class="relative z-10" @close="closeRegisterModal">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center flex-wrap content-center min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
							<XIcon class="block h-6 w-6 absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700" @click="closeRegisterModal"/>
							<div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                                <div class="max-w-md w-full space-y-8">
                                    <div>
                                        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ $t("register.title") }}</h2>
                                    </div>
                                    <form class="mt-8 space-y-6" @submit="onSubmitRegister($event)">
                                        <div class="rounded-md shadow-sm -space-y-px">
                                            <div class="flex h-7 mb-3">
                                                <p v-if="error"
                                                   class="h-7 rounded-md text-red-600 text-sm w-full text-center bg-red-100 pt-1">
                                                    {{ error }}</p>
                                            </div>
                                            <form-input id="email"
                                                        fieldName="email"
                                                        type="text"
                                                        autocomplete="email"
                                                        label="E-mail"
                                                        :placeholder="$t('register.email')"
                                                        v-model="email"
                                                        :required="true"
                                                        customClass="rounded-t-md"
                                            ></form-input>
                                            <form-input id="username"
                                                        fieldName="username"
                                                        type="text"
                                                        autocomplete="username"
                                                        label="Username"
                                                        :placeholder="$t('register.username')"
                                                        v-model="username"
                                                        :required="true"
                                            ></form-input>
                                            <form-input id="firstname"
                                                        fieldName="firstname"
                                                        type="text"
                                                        autocomplete="firstname"
                                                        label="First Name"
                                                        :placeholder="$t('register.first_name')"
                                                        v-model="firstname"
                                                        :required="true"
                                            ></form-input>
                                            <form-input id="lastname"
                                                        fieldName="lastname"
                                                        type="text"
                                                        autocomplete="lastname"
                                                        label="Last Name"
                                                        :placeholder="$t('register.last_name')"
                                                        v-model="lastname"
                                                        :required="true"
                                            ></form-input>
                                            <form-input id="password"
                                                        fieldName="password"
                                                        type="password"
                                                        autocomplete="current-password"
                                                        label="Password"
                                                        :placeholder="$t('register.password')"
                                                        v-model="password"
                                                        :required="true"
                                                        customClass="rounded-b-md"
                                            ></form-input>
                                        </div>

                                       <DropDown
                                           :items="currencies"
                                           :selectedItem="currency"
                                           :generateLabel="getCurrencyLabel"
                                           :defaultTitle="$t('register.select_currency')"
                                           @select-item="selectCurrency"
                                       />
										<DropDown
											:items="languages"
											:selectedItem="language"
											:generateLabel="getLanguageLabel"
											:defaultTitle="$t('register.select_language')"
											@select-item="selectLanguage"
										/>
										<DropDown
											:items="oddsFormat"
											:selectedItem="oddFormat"
											:generateLabel="getOddFormatLabel"
											:defaultTitle="$t('register.select_odds_format')"
											@select-item="selectOddFormat"
										/>

                                        <div>
                                            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
												<span class="absolute left-0 inset-y-0 flex items-center pl-3">
													<LockClosedIcon class="h-5 w-5 text-white group-hover:text-gray-200" aria-hidden="true" />
												</span>
                                                {{ $t('register.sign_up') }}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { LockClosedIcon } from '@heroicons/vue/solid'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { defineComponent } from "vue";
import FormInput from "@/components/FormInput.vue";
import DropDown from "./DropDown.vue";
import userActions from '../store/user/user-actions'
import { XIcon } from "@heroicons/vue/outline";
import { sendPostMessage, siteConfig } from "@/composables/helpers";
import { SiteConfig, Currency, Language, OddFormat } from "@/store/types/types";

export default defineComponent({
    name: "register-modal",
    components: {
        DropDown,
		XIcon,
        FormInput,
        Dialog,
        DialogPanel,
        TransitionRoot,
        TransitionChild,
        LockClosedIcon,
    },
    setup() {
        const store = useStore();
        const email = ref('');
        const username = ref('');
        const firstname = ref('');
        const lastname = ref('');
        const password = ref('');
        const error = ref('');
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const currencies = siteConfig().currencies ?? [] as SiteConfig['currencies'];
        const currency = ref(null as Currency | null);
		const languages = siteConfig().languagesList ?? [] as SiteConfig['languagesList'];
		const language = ref(null as Language | null);
		const oddsFormat = siteConfig().oddsFormat ?? [] as SiteConfig['oddsFormat'];
		const oddFormat = ref(null as OddFormat | null);

        function closeRegisterModal() {
            store.commit('OPEN_REGISTER_MODAL', false)
            clearRegisterData()
            error.value = '';
        }

        function onSubmitRegister(e: any) {
            e.preventDefault();
            error.value = '';
            if (!emailRegex.test(email.value)) {
                error.value = 'Invalid email address';
                return
            }
            if (currency.value === null) {
                error.value = 'Currency selection is required';
                return
            }
			if (language.value === null) {
				error.value = 'Language selection is required';
				return
			}
			if (oddFormat.value === null) {
				error.value = 'Odds format selection is required';
				return
			}

            store.dispatch(userActions.REGISTER_USER, {
                firstname: firstname.value,
                lastname: lastname.value,
                username: username.value,
                email: email.value,
                password: password.value,
                currency: currency?.value?.id,
				language: language?.value?.title,
				'odds-format': oddFormat?.value?.key,
				'user-locale': `en_EN`,
            }).then((response: {status: string, message: string}) => {
                if (response.status === 'error') {
                    error.value = response.message;
                } else {
                    store.dispatch(userActions.LOGIN_USER, {
                        username: username.value,
                        password: password.value,
					}).then(() => {
						const languageCode = language.value?.path.slice(1).toLowerCase();
						if (languageCode) {
							store.commit('SET_SELECTED_LANGUAGE', languageCode);
							localStorage.setItem("selectedLanguage", languageCode);
						}
						if (oddFormat.value) {
							sendPostMessage('SET_USER_ODDS_FORMAT', oddFormat.value.key);
						}
						closeRegisterModal();
					});
                }
            })
        }

        function clearRegisterData() {
            email.value = ''
            username.value = ''
            firstname.value = ''
            lastname.value = ''
            password.value = ''
            currency.value = null
			language.value = null
			oddFormat.value = null
        }

        function selectCurrency(selectedCurrency: Currency) {
            currency.value = selectedCurrency;
        }

        function getCurrencyLabel(currency: Currency) {
            return `${currency.title} - ${currency.code}`;
        }

		function selectLanguage(selectedLanguage: Language) {
			language.value = selectedLanguage;
		}

		function getLanguageLabel(language: Language) {
			return `${language.title} - ${language.path.slice(1).toUpperCase()}`;
		}

		function selectOddFormat(selectedOddFormat: OddFormat) {
			oddFormat.value = selectedOddFormat;
		}

		function getOddFormatLabel(oddFormat: OddFormat) {
			return `${oddFormat.title}`;
		}

        return {
            isRegisterModalOpen: computed(() => store.state.application.openRegisterModal),
            closeRegisterModal,
            email,
            username,
            firstname,
            lastname,
            password,
            onSubmitRegister,
            clearRegisterData,
            error,
            selectCurrency,
            getCurrencyLabel,
			selectLanguage,
			getLanguageLabel,
			selectOddFormat,
			getOddFormatLabel,
            currencies,
            currency,
			languages,
			language,
			oddsFormat,
			oddFormat,
        }
    }
});
</script>
